import { ReactElement } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  CheckboxProps,
  FormControlLabel,
  Radio,
  RadioGroupProps,
  Stack,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import Select, { SelectOption, SelectProps } from 'components/Select';
import TextField from 'components/TextField';
import DatePicker, { DatePickerProps } from 'components/DatePicker';
import { getProperty } from 'helpers';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import Autocomplete from 'components/Autocomplete';
import {
  AutocompleteOption,
  AutocompleteProps,
} from 'components/Autocomplete/interfaces';
import GrowingTextField from 'components/ExpandableTextarea';
import Toggle from 'components/Toggle';
import MultiselectAutocomplete from 'components/MultiselectAutocomplete';
import RadioGroup from 'components/Radio/RadioGroup.component';
import Checkbox from 'components/Checkbox';
import { PickerOnChangeFn } from '@mui/x-date-pickers/internals/hooks/useViews';
import { UnitedReactHookFormFieldProps } from './interfaces';
import { ToggleProps } from '../Toggle/interfaces';

export default function ReactHookFormField(
  props: UnitedReactHookFormFieldProps,
) {
  const {
    rules,
    name,
    type,
    label,
    placeholder = '',
    required,
    fieldProps,
    disabled,
    dataTestId,
    withoutError,
    withoutTooltip,
    withPlaceholderWhenOptionNotFound,
  } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const renderField: ControllerProps['render'] = ({
    field: { ref, ...field },
  }): ReactElement => {
    const error = getProperty(errors, name);
    let errorMessage = error?.message;
    if (error && !errorMessage) {
      errorMessage = error[Object.keys(error)[0]];
    }
    const baseProps = {
      size: 'large',
      ...fieldProps,
      ...field,
      label,
      key: name,
      placeholder,
      required: required || typeof rules?.required !== 'undefined',
      disabled,
      'data-testid': dataTestId,
      withoutTooltip,
    };
    const extendedProps = {
      ...baseProps,
      fullWidth: true,
      error: withoutError ? false : Boolean(error),
      helperText: withoutError ? '' : errorMessage,
      inputRef: ref,
    };
    switch (type) {
      case 'select': {
        const {
          options,
          multiple,
          groupBy,
          displayEmpty,
          clearValue,
          onChange,
        } = props;

        return (
          <Select
            {...(extendedProps as SelectProps<SelectOption, unknown>)}
            options={options}
            groupBy={groupBy}
            multiple={multiple}
            displayEmpty={displayEmpty}
            clearValue={clearValue}
            withPlaceholderWhenOptionNotFound={
              withPlaceholderWhenOptionNotFound
            }
            onChange={(e) => {
              extendedProps?.onChange?.(e.target.value);
              onChange?.(e.target.value as number);
            }}
          />
        );
      }
      case 'autocomplete': {
        const { options, multiple, onChange } = props;
        const { value, ...autocompleteProps } = extendedProps;

        return (
          <Autocomplete
            {...(autocompleteProps as Omit<
              AutocompleteProps<
                AutocompleteOption,
                boolean | undefined,
                boolean | undefined,
                boolean | undefined
              >,
              'options'
            >)}
            options={options}
            onChange={(e, newValue) => {
              if (Array.isArray(newValue)) {
                extendedProps.onChange(
                  newValue.map((multiSelectItem) =>
                    typeof multiSelectItem === 'string'
                      ? multiSelectItem
                      : multiSelectItem.id,
                  ),
                );
                return;
              }

              extendedProps.onChange(
                typeof newValue === 'string' ? newValue : newValue?.id,
              );

              onChange?.(newValue);
            }}
            value={
              multiple
                ? options.filter(({ id }) => value?.includes(id))
                : options.find(({ id }) => id === value) || null
            }
            multiple={multiple}
          />
        );
      }
      case 'multiselect-autocomplete': {
        const { options, customTagsMessage, removeSelection } = props;
        const { value, ...autocompleteProps } = extendedProps;

        return (
          <MultiselectAutocomplete
            {...(autocompleteProps as Omit<
              AutocompleteProps<AutocompleteOption, true, false, false>,
              'options'
            >)}
            options={options}
            selectedOptions={value}
            removeSelection={removeSelection}
            onChange={(e, newValue) => {
              if (Array.isArray(newValue)) {
                extendedProps.onChange(
                  newValue.map((multiSelectItem) =>
                    typeof multiSelectItem === 'string'
                      ? multiSelectItem
                      : multiSelectItem.id,
                  ),
                );
              }
            }}
            customTagsMessage={customTagsMessage}
            value={options.filter(({ id }) => value?.includes(id))}
          />
        );
      }
      case 'datepicker': {
        const { iconOnly, onChange } = props;
        const customOnChange: PickerOnChangeFn<string> = (
          date,
          selectionState,
        ) => {
          onChange?.(date);
          baseProps.onChange(date, selectionState);
        };
        return (
          <DatePicker
            {...(extendedProps as DatePickerProps)}
            onChange={customOnChange}
            iconOnly={iconOnly}
          />
        );
      }
      case 'growing-text': {
        const { maxLength, selectOnFocus } = props;
        const { inputProps = {} } = fieldProps || {};
        if (maxLength) {
          Object.assign(inputProps, { maxLength });
        }
        return (
          <GrowingTextField
            {...(extendedProps as TextFieldProps)}
            inputProps={inputProps}
            type="text"
            multiline
            selectOnFocus={selectOnFocus}
          />
        );
      }
      case 'toggle': {
        const { value } = extendedProps;
        return (
          <Toggle
            inputRef={ref}
            checked={value}
            {...(baseProps as ToggleProps)}
          />
        );
      }
      case 'radio': {
        const {
          options,
          labelPlacement = 'top',
          tooltipPlacement = 'top',
          tooltipTitle,
          onChange,
        } = props;

        const customOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange?.(e);
          baseProps.onChange(e);
        };

        return (
          <RadioGroup
            {...fieldProps}
            {...(baseProps as RadioGroupProps)}
            onChange={customOnChange}
          >
            {options.map((option) => (
              <Box
                key={option.id}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Tooltip placement={tooltipPlacement} title={tooltipTitle}>
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={<Radio />}
                    disabled={disabled}
                    label={option.label || option.value}
                    labelPlacement={labelPlacement}
                  />
                </Tooltip>
                {option.tooltip}
              </Box>
            ))}
          </RadioGroup>
        );
      }
      case 'checkbox': {
        const { value = false } = extendedProps;
        return (
          <Stack direction="row" alignItems="center">
            <Checkbox
              inputRef={ref}
              checked={value}
              {...(baseProps as CheckboxProps)}
            />
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">{label}</Typography>
            </Stack>
          </Stack>
        );
      }
      default: {
        const { maxLength, selectOnFocus } = props;
        const { inputProps = {} } = fieldProps || {};
        if (maxLength) {
          Object.assign(inputProps, { maxLength });
        }
        return (
          <TextField
            {...(extendedProps as TextFieldProps)}
            inputProps={inputProps}
            type={type === 'password' ? type : 'text'}
            multiline={type === 'textarea'}
            selectOnFocus={selectOnFocus}
          />
        );
      }
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={renderField}
    />
  );
}
