import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import { OptionsState } from './interfaces';

export const selectOptions = (state: RootState) => state.options;

export const selectOptionsForDepartmentOverviewPage = createSelector(
  (state: RootState) => state.options,
  (options: OptionsState) => ({
    ...options,
    units: options.units.filter(({ name }) => name !== 'PP'),
  }),
);

export const selectDefaultAssessmentScaleGroupId = (state: RootState) =>
  state.options.assessmentScaleGroups.find(({ alias }) => alias === 'standard')
    ?.id ||
  state.options.assessmentScaleGroups.find(({ alias }) => alias === 'general')
    ?.id ||
  '';

export const selectAssessmentScaleGroups = (state: RootState) =>
  state.options.assessmentScaleGroups;

export const selectAssessmentScales = (state: RootState) =>
  state.options.assessmentScales;

export const selectAllUnits = (state: RootState) => state.options.units;

export const selectUserScopedUnits = (state: RootState) =>
  state.options.userScopedUnits;

export const selectUserScopedLocations = (state: RootState) =>
  state.options.userScopedLocations;

export const selectUserScopedReviewCycles = (state: RootState) =>
  state.options.userScopedReviewCycles;

export const selectUsersOptions = (state: RootState) => state.options.users;
