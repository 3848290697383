import { useSelector } from 'react-redux';
import {
  AssessmentScaleGroupOption,
  selectAssessmentScaleGroups,
  selectAssessmentScales,
} from 'store/options';
import { FormattedMessage } from 'react-intl';
import { formTexts } from 'i18n';
import { AssessmentScaleGroupAlias } from 'store/interfaces/ScorecardExternalSubgoalRO';
import { AssessmentScale } from 'store/interfaces';

const getHeading = (optionName: string, options: AssessmentScale[]) => {
  const optionsList = (
    <ul>
      {options?.map(({ name }) => (
        <li key={name}>{name}</li>
      ))}
    </ul>
  );

  switch (optionName) {
    case AssessmentScaleGroupAlias.TEXT:
      return <FormattedMessage {...formTexts.textShortDescription} />;
    case AssessmentScaleGroupAlias.PM_SCALE:
    case AssessmentScaleGroupAlias.STANDARD:
      return (
        <>
          <FormattedMessage {...formTexts.attainmentLevels} />
          {optionsList}
        </>
      );
    case AssessmentScaleGroupAlias.NPS:
      return (
        <>
          <FormattedMessage {...formTexts.npsShortDescription} />
          <br />
        </>
      );
    case AssessmentScaleGroupAlias.GRANULAR:
      return (
        <>
          <FormattedMessage {...formTexts.attainmentLevels} />
          <br />
          <br />
        </>
      );
    default:
      return null;
  }
};

const getDescription = (optionName: string) => {
  switch (optionName) {
    case AssessmentScaleGroupAlias.PM_SCALE:
      return formTexts.pmScaleDescription;
    case AssessmentScaleGroupAlias.STANDARD:
      return formTexts.standardScaleDescription;
    case AssessmentScaleGroupAlias.GENERAL:
      return formTexts.generalScoreMapping;
    case AssessmentScaleGroupAlias.NPS:
      return formTexts.npsScoreMapping;
    case AssessmentScaleGroupAlias.TEN_GRADE:
      return formTexts.tenGradeScaleDescription;
    case AssessmentScaleGroupAlias.GRANULAR:
      return formTexts.granularScaleDescription;
    default:
      return '';
  }
};

export default function useGoalAssessmentValues(subGoalName: string) {
  const assessmentScaleGroupIdName = `${subGoalName}.assessmentScaleGroupId`;
  const assessmentScales = useSelector(selectAssessmentScales);
  const allAssessmentScaleGroups = useSelector(selectAssessmentScaleGroups);
  const assessmentScaleGroups = useSelector(selectAssessmentScaleGroups)
    ?.filter((item) => item.isActive)
    ?.map((item) => {
      const result: AssessmentScaleGroupOption & {
        tooltipWithIcon?: JSX.Element;
      } = {
        ...item,
      };

      const options = assessmentScales[item.id];
      const heading = getHeading(item.alias, options);
      const description = getDescription(item.alias);

      result.tooltipWithIcon = (
        <div style={{ whiteSpace: 'pre-line' }}>
          {heading}
          {description && (
            <FormattedMessage
              {...description}
              values={{
                li: (...chunks: any) => <li>{chunks}</li>,
                ol: (...chunks: any) => <ol>{chunks}</ol>,
                ul: (...chunks: any) => <ul>{chunks}</ul>,
                br: <br />,
              }}
            />
          )}
        </div>
      );
      return result;
    });

  return {
    assessmentScaleGroupIdName,
    assessmentScaleGroups,
    allAssessmentScaleGroups,
  };
}
